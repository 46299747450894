.cal-button {
  margin: 10px 0;

  text-align: center;
  width: 100%;
}
.button {
  margin-left: 10px;
}

.spin {
  vertical-align: bottom;
  margin-left: 70px;
}
.latex {
  margin: 10px;
}

.container {
  text-align: center;
  width: 100%;
}

.title {
  background: #282c34;
  color: white;
  line-height: 40px;
  margin: 10px 0;
  padding-left: 10px;
}
.imgs {
  padding-left: 10px;
  img {
    max-width: 100%;
  }
}
.result {
  max-width: 100%;
  overflow: hidden;
}

.footer {
  height: 200px;
  text-align: center;
}

.hint {
  width: 100%;
  text-align: center;
}
