.img-container {
  width: 100%;
  text-align: center;
  box-shadow: lightgray 1px 1px 1px;
  margin-bottom: 20px;
  min-height: 200px;

  .img {
    max-width: 100%;
    max-height: 50vh;
    margin: auto;
    display: block;
  }
}
