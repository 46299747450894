.img-field {
  width: 100%;
  text-align: center;
}

.container {
}

.button-container {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  border-top: blueviolet;
  box-shadow: lightgray 1px -2px 10px;
  position: fixed;
  bottom: 0;
  padding: 10px;
  text-align: center;
  background: white;

  .button {
    margin-left: 10px;
    margin-right: 10px;
  }
}
